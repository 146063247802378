.mainContainer {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    font-family: 'Golos', sans-serif;
    height: 100%;
    min-height: 460px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.title {
    font-size: 20px;
    color: #26252D;
    font-family: "Golos Medium";
}

.mainTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3em;
}

.subTitle {
    font-size: 14px;
    color: #8D96B2;
}

.status {
    font-size: 14px;
    color: #8D96B2;
}

.dataContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.infoBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.infoLabel {
    font-size: 14px;
    color: #8D96B2;
}

.infoValue {
    font-size: 16px;
    color: #1B2134;
    font-weight: 500;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.gridItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3em;
}

.stagesContainer {
    margin-top: 16px;
}
