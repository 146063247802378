.tooltip {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    position: fixed;
    font-family: 'Golos Regular';
    max-width: 300px;
    background-color: #FFF;
    color: #315EFB;
    padding: 0.7em;
    border-radius: 5px;
    font-size: 12px;
    pointer-events: none;
    white-space: pre-wrap;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
