.mainContainer {
    padding: 20px;
}

.container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    align-items: flex-start;
}

.contractInfo {
    flex: 1;
    min-width: 60%;
}

.scoringPercentageAlerts {
    flex: 1;
    min-width: 35%;
    max-width: 300px;
}

