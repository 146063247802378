.alertContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.smVh {
    height: 100%;
    max-height: 300px;
    overflow-y: auto;
}

.percentage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.score {
    font-size: 32px;
    font-weight: 700;
    color: black;
}

.label {
    font-size: 16px;
    color: #6B7280;
    margin: 6px 0;
}

.progressBar {
    width: 100%;
    height: 12px;
    background: #E5E7EB;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
}

.progress {
    width: 79%;
    height: 100%;
    background: linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%);
    transition: width 0.4s ease-in-out;
}

.alerts {
    width: 100%;
    font-size: 16px;
    color: #6B7280;
    text-align: left;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.alertTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.alertTable th {
    font-size: 14px;
    color: #9CA3AF;
    text-align: left;
    padding: 10px;
}

.alertTable td {
    font-size: 14px;
    padding: 10px;
}

.greenText {
    color: #10B981;
    font-weight: 600;
}

.redText {
    color: #EF4444;
}
