.appContainer {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    display: flex;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
}

.contentContainer {
    margin: 20px;
}

.mainContainer {
    overflow: hidden;
    width: 100%;
    min-height: 100%;
    background-color: var(--main-container-bg-color);
    transition: background-color 0.5s ease-in;
}

.fixedSticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 12px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    margin-left: -70px;
}


