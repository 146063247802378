.container {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.tableWrapper {
    position: relative;
    height: 500px;
    border-radius: 16px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.tableScrollArea {
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 16px 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Golos Text", sans-serif;
    font-size: 14px;
    color: #333;
}

.hidden {
    visibility: hidden;
}

.table th {
    position: sticky;
    top: 0;
    z-index: 2;
    text-align: left;
    font-weight: 500;
    padding: 12px 16px;
    color: #606060;
    background-color: #ffffff;
}

.table td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
}

.table tr:hover {
    background-color: #f9fafb;
}

.selected td {
    background-color: rgba(205, 187, 248, 0.46);
}

.appendLoader {
    display: flex;
    justify-content: center;
    padding: 16px 0;
}

.contractName {
    max-width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
    line-height: 1.2;
}
