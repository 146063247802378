.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--container-bg-color);
    color: var(--text-color);
    height: 80px;
    border-bottom: 2px solid var(--border-color);
    transition: background-color 0.5s ease-in, color 0.3s ease-in, border-bottom 0.5s ease-in;
}

.headerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerRight{
    display: flex;
    flex-direction: row;
}

.title {
    font-family: 'Golos Regular';
    margin-left: 40px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.menuItemContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 6px;
}
