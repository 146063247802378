
.containerContent {
  width: 100%;
  height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
}

.containerContent.zoomed {
  height: auto;
}

.containerContent::-webkit-scrollbar {
  width: 5px;
}

.containerContent::-webkit-scrollbar-thumb {
  background: var(--scroll-bg-color);
  border-radius: 10px;
}

.containerContent::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bg-color-hover);
}


