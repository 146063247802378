
.toggleButton {
    transition: background-color 0.3s, border-color 0.3s, color 0.3s !important;
    font-Family: 'Golos Regular' !important;
    font-Size: 12px !important;
    text-transform: none !important;
}

.toggleButton.active {
    background-color: #DADBFF !important;
    color: black !important;
    border-color: #DADBFF !important;
}

.companiesContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    padding: 0.5em;
    border: 1px solid #8D96B2;
    border-radius: 5px;
    max-height: 50vh !important;
    overflow-y: auto;
}

.trashIco {
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.companyMapContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmModal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    width: 20vw;
    padding: 20px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 2px 6px 40px 0px rgba(0, 0, 0, 0.15);
    transition: visibility 0.2s ease;
    visibility: hidden;
    pointer-events: none;
}

.confirmModalActive {
    visibility: visible;
    pointer-events: auto;
    animation: confirmModalAnimation 0.2s ease-in-out forwards;
}

@keyframes confirmModalAnimation {
    from {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

.confirmModalBtnGroup {
    display: flex;
    flex-direction: row;
    gap: 0.2em;
}

.maskInput {
    width: 100%;
    padding: 4px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 14px;
    font-family: "Golos Regular";
    border-radius: 6px;
    border: 1px solid #8D96B2;
}

.errorConatiner {
    color: red;
    text-align: center;
    margin-top: 10px;
}
